<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit?'编辑科室':'新增科室'" :visible.sync="show" width="600px" class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" label-width="80px">

            <el-form-item label="科室名称" prop="">
                <el-input v-model="levelInfo.name" />
            </el-form-item>



            <el-form-item label="所属医院" prop="">
                <el-select v-model="levelInfo.dep_id" placeholder="请选择医院">
                    <el-option :label="item.name" :value="item.id" v-for="item in depList" :key="item.id"></el-option>
                </el-select>
            </el-form-item>



            <el-form-item label="状态" prop="">
                <el-switch v-model="levelInfo.status" :active-value="1" active-color="#5BD995" active-text="正常"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                </el-switch>
            </el-form-item>



            <el-form-item label="排序" prop="">
                <el-input v-model="levelInfo.sort" />
            </el-form-item>



        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>
    </el-dialog>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'


    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                radio: '1',




                levelInfo: {
                    name: "",
                    dep_id: "",
                    status: 1,
                    sort: "",
                },

                depList: [],


                isEdit: false

            }
        },

        computed: {
            ...mapState('m_item', ['LevelList', 'PositionList', 'DepList', 'DepTree', 'HealthList', 'Province', 'City',
                'District',
                'RoleList', 'Depcate'
            ]),
        },

        mounted() {

        },

        created() {

        },

        watch: {

            show(value) {
                if (this.show) {

                    if (this.depList.length == 0) {
                        this.getdep()
                        setTimeout(() => {
                            // this.depList = this.DepList

                            this.depList = this.DepTree

                        }, 1000)
                    }


                } else {

                    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");

                    this.isEdit = false

                    this.levelInfo = {
                        name: "",
                        dep_id: "",
                        status: 1,
                        sort: "",
                    }

                }
            },

        },

        methods: {

            open() {
                this.formShow = true
                this.show = true
            },


            edit(row) {
                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", row);

                this.isEdit = true

                this.open()

                this.$http.get('/api/level/' + row.id).then((res) => {
                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                        return;
                    }
                    if (res.code == 200) {
                        this.levelInfo = {
                            ...row
                        }
                    }
                }).catch((err) => {
                    console.log(err)
                });

            },

            refresh() {

                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

            save() {

                console.log(this.levelInfo);

                if (this.isEdit) {

                    this.$http.put('/api/level/' + this.levelInfo.id, this.levelInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                        if (res.code == 200) {

                            this.refresh()

                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                } else {

                    this.$http.post('/api/level', this.levelInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);
                        }
                        if (res.code == 200) {
                            this.refresh()
                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                }

            }

        }
    }
</script>

<style scoped>
    /* 	.selfInputBoxIV .el-input__inner[placeholder="请选择所属卫健委"] {
		border: 1px red solid !important;
		box-sizing: content-box !important;
	} */

    .selfInputBoxIV .el-input__suffix {
        /*    border: 1px red solid !important;
    box-sizing: content-box !important; */
        right: -10px;
    }
</style>

<style scoped lang="scss">
    .trisection {
        .el-select {
            /deep/ .el-input__inner {
                // border: 1px red solid !important;
                box-sizing: content-box !important;
            }
        }
    }
</style>